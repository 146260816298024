.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: #e5751f; /* Orange color from the logo */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.suggestions {
  margin-top: 5px;
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #ccc;
  width: 300px;
  background-color: #fff;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  font-size: 14px;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.category-pills {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  max-width: 300px;
}

.category-pill {
  background-color: #d3d3d3;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #000;
  opacity: 0;
  transform: scale(0.95);
  transition: transform 0.2s ease, opacity 0.2s ease;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.category-pill .remove-pill {
  margin-left: 10px;
  cursor: pointer;
  color: #ff0000;
}

.category-pill.removing {
  opacity: 0;
  transform: scale(0.95);
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.place-id-container {
  display: flex;
  align-items: center;
}

.loading-spinner {
  margin-left: 10px;
  width: 25px;
  height: 25px;
  border: 3px solid #e5751f; /* The orange color from the logo */
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.submit-button.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-loading-spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff; /* White color for the loading spinner */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

/* Informational text for Google Place ID */
.info-text {
  font-size: 12px; /* Smaller text size */
  color: #ffffff; /* White color for the text */
  margin-top: 5px;
}

.info-link {
  color: #e5751f; /* Brand orange color */
  text-decoration: none;
}

.info-link:hover {
  text-decoration: underline;
}

.info-link:visited {
  color: #e5751f; /* Ensure visited links stay orange */
}
