.remove-button {
  margin-top: 20px;
  background-color: #ff4d4d; /* Red color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.remove-button:hover {
  background-color: #d94444; /* Darker red for hover effect */
}
