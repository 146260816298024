.App {
  text-align: center;
  height: 100vh; /* Ensure the app takes full viewport height */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  overflow: hidden; /* Prevent unwanted scrolling */
}

.App-logo {
  width: 50%;
  pointer-events: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.App-header {
  background-color: #282c34;
  height: 100%; /* Take up full height to cover the entire screen */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header select {
  margin: 10px 0; /* Adjusted margin to keep the dropdown closer to the text */
  padding: 10px;
  font-size: 16px;
  width: 300px;
  border-radius: 8px;
}

input[type="text"],
textarea {
  margin: 10px 0;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 300px;
  display: block;
  box-sizing: border-box;
}

.submit-button {
  margin-top: 20px;
  background-color: #e5751f;
  border-style: none;
}

button {
  width: 300px;
  height: 40px;
  font-size: medium;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 20px;
  border-style: none;
}

button:hover {
  background-color: orange;
}

.logoutButton {
  margin-top: 40px;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;
}

.no-selection-message {
  font-size: 14px;
  color: white;
  margin-top: 10px; /* Reduced margin to make the text closer to the dropdown */
}
