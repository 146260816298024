.edit-form .form-label {
  display: block; /* Ensures the label takes up full width */
  font-size: 12px;
  margin-bottom: 0spx;
  text-align: left;
}

.edit-form .form-input,
.edit-form .form-textarea {
  margin-bottom: 12px;
  width: 100%;
}

.form-textarea {
  resize: vertical;
}
