.view-locations {
  padding: 20px;
}

.search-input {
  margin-bottom: 20px;
  padding: 8px;
  width: 100%; /* This ensures the search bar is the same width as the table */
  box-sizing: border-box;
  font-size: 14px; /* Font size for search input */
}

.table-container {
  max-height: 400px; /* Set the maximum height for the table container */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ddd; /* Border to better define the container */
}

.locations-table {
  width: 100%; /* Ensure the table takes up the full width of its container */
  border-collapse: collapse;
}

.locations-table th,
.locations-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 14px; /* Font size for table content */
}

.locations-table th {
  font-size: 16px; /* Font size for table headers */
}
